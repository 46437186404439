<!-- @Author: Yu_Bo 2023-09-05 18:28:44-->
<template>
    <div class="memberlist_vue">
        <!-- 标题 -->
        <div class="vue_title">会员列表</div>
        <!--  -->
        <div class="picture_main">
            <div class="main_condition">
                <div class="cond_left">
                    <el-input v-model="searchInfo.mobile" size="small" @keyup.enter.native="searchBtn" @clear='searchBtn' :clearable="true" placeholder="请输入登录手机号"></el-input>
                    <el-input v-model="searchInfo.nickname" size="small" @keyup.enter.native="searchBtn" @clear='searchBtn' :clearable="true" placeholder="请输入昵称"></el-input>
                    <el-select v-model="searchInfo.mtype" size="small" @change="searchBtn" :clearable="true" placeholder="请选择所属领域">
                        <el-option v-for="item in areaList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-input v-model="searchInfo.wechat_num" size="small" @keyup.enter.native="searchBtn" @clear='searchBtn' :clearable="true" placeholder="请输入微信号"></el-input>
                    <el-date-picker size='small' v-model="dateTime" type="daterange"
                        range-separator="至" start-placeholder="VIP开始日期" end-placeholder="VIP结束日期" value-format="yyyy-MM-dd"
                        @change="searchBtn" :clearable="true">
                    </el-date-picker>
                </div>
                <div class="cond_right">
                    <el-button type="text" @click="templateDownload">模板下载</el-button>
                    <el-upload :accept="acceptTxt" action="" :show-file-list="false" :before-upload="beforeUpload" :http-request="httpRequestMultiple">
                        <el-button type="primary" class="btnBgColor_blue_empty" size="small" :loading="btnLoading">导入用户</el-button>
                    </el-upload>
                </div>
            </div>
            <!--  -->
            <div class="main_table">
                <el-table :data="list" v-loading="loading" style="width: 100%" header-cell-class-name="table-header" cell-class-name="cell-class">
                    <template slot="empty">
                        <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                    </template>
                    <el-table-column prop="id" label="用户ID" width="100" align="center"></el-table-column>
                    <el-table-column prop="mobile" label="登录手机号" align="center"></el-table-column>
                    <el-table-column prop="nickname" label="昵称" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.nickname || '--' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="mtype_text" label="所属领域" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.mtype_text || '--' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="realname" label="真实姓名" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.realname || '--' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="wechat_num" label="微信号" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.wechat_num || '--' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="VIP有效时间" align="center" width="220">
                        <template slot-scope="scope">
                            <span v-if="scope.row.vip_start && scope.row.vip_end">{{ scope.row.vip_start+'至'+scope.row.vip_end }}</span>
                            <span v-else>--</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" align="center">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.status" :active-value='1' :inactive-value='2' :key="scope.row.id" @change="changeSwitch($event,scope.row,scope.$index)"></el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="创建时间" align="center"></el-table-column>
                    <el-table-column label="操作" align="center" fixed="right" width="120">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="details(scope.row.id)">详情</el-button>
                            <el-button type="text" size="small" @click="openMember(scope.row)">开通会员</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div class="page_box" v-if="total" style="padding-bottom: 20px;">
                <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
            </div>
        </div>
        <!-- 详情 -->
        <member-details ref="details"></member-details>
        <!-- 开通会员 -->
        <member-open ref="open" @submit="getList"></member-open>
    </div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import MemberDetails from '../components/member_details/index'//详情
import MemberOpen from '../components/member_open/index'//开通会员

export default {
    components: { EmptyData, PagingPage, MemberDetails, MemberOpen },
    props: {},
    data() {
        return {
            // 名称
            searchInfo:{
                mobile:'',
                nickname:'',
                mtype:'',
                wechat_num:'',
            },
            dateTime:'',
            areaList:[],
            // 
            btnLoading: false,
            acceptTxt:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',
            // 
            list: [],
            loading: false,
            //
            page: 1,
            limit: 10,
            total: 0,
        };
    },


    created() {
        this.getParams()
        this.getList()
    },

    methods: {
        getParams() {
            this.$memberApi.getParams().then(res => {
                if (res.code == 1000) {
                    this.areaList = res.result
                }
            })
        },
        // 数据列表
        getList() {
            this.loading = true
            var params = {
                ...this.searchInfo,
                vip_start:this.dateTime?this.dateTime[0]:'',
                vip_end:this.dateTime?this.dateTime[1]:'',
                page: this.page,
                limit: this.limit,
            }
            this.$memberApi.user_list(params).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.list = res.result.data
                    this.total = res.result.total
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 筛选搜索
        searchBtn() {
            this.page = 1
            this.total = 0
            this.getList()
        },
        // 改变每页条数
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList()
        },
        // 改变页数
        currentChangeBtn(val) {
            this.page = val
            this.getList()
        },
        // 修改状态
        changeSwitch(val, info, index) {
            var params = {
                id: info.id,
                status: val
            }
            this.$memberApi.change_status(params).then(res => {
                if (res.code == 1000) {
                    this.list[index].status = val
                    this.$succMsg(res.message)
                } else {
                    this.list[index].status = val == 1 ? 2 : 1
                    this.$errMsg(res.message)
                }
            })
        },
        // 详情
        details(id){
            this.$refs.details.opendialog(id)
        },
        // 开通会员
        openMember(info){
            this.$refs.open.opendialog(info)
        },
        // 导入用户
        beforeUpload(file){
            var arr = this.acceptTxt.split(',')
            const isType = arr.includes(file.type)
            if (!isType) {
                this.$message.error('只能上传 xls、xlsx 格式的文件!');
            }
            return isType
        },
        httpRequestMultiple(uploader){
            console.log(uploader)
            this.btnLoading = true
            const newFile = new FormData();
            newFile.append('file', uploader.file);
            this.$memberApi.import_user(newFile).then(res => {
                this.btnLoading = false
                if (res.code === 1000) {
                    this.$succMsg(res.message)
                    this.getList()
                } else {
                    this.$message.error(res.message);
                }
            })
        },
        // 下载模板
        async templateDownload(){
            var tem_url = 'https://yc-digital.oss-cn-shenzhen.aliyuncs.com/file/20231020/arIIULPeZrbp-dtgAW.zip'
            window.open(tem_url, '_blank')
        },
    }
}
</script>
<style lang='scss' scoped>
.memberlist_vue {
    width: 100%;
    .picture_main {
        width: 100%;
        padding: 0 20px;
        .main_condition {
            width: 100%;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .cond_left {
                display: flex;
                align-items: center;
                .el-select {
                    margin-right: 10px;
                    width: 150px;
                }
                .el-input {
                    margin-right: 10px;
                    width: 150px;
                }
                .el-date-editor{
                    width: 250px;
                }
            }
            .cond_right{
                display: flex;
                align-items: center;
                .el-button {
                    padding: 8px 10px;
                    margin-left: 10px;
                }
            }
        }
    }
}
</style>