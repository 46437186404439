<!-- @Author: Yu_Bo 2023-10-19 14:24:21-->
<template>
    <div class="member_details zc_dialog_box">
        <el-dialog :visible.sync="dialogVisible" width="740px" :close-on-click-modal='false' @close='cancelBtn'>
            <div slot="title">
                <div class="name">{{ title }}</div>
            </div>
            <div v-loading="loading">
                <div class="details_info">
                    <div class="info">
                        <div class="left">用户ID:</div>
                        <div class="right">{{ info.id || '--' }}</div>
                    </div>
                    <div class="info">
                        <div class="left">昵称:</div>
                        <div class="right">{{ info.nickname || '--' }}</div>
                    </div>
                    <div class="info">
                        <div class="left">登录手机号:</div>
                        <div class="right">{{ info.mobile || '--' }}</div>
                    </div>
                    <div class="info">
                        <div class="left">所属领域:</div>
                        <div class="right">{{ info.mtype_text || '--' }}</div>
                    </div>
                    <div class="info">
                        <div class="left">公司职位:</div>
                        <div class="right">{{ info.corp || '--' }}</div>
                    </div>
                    <div class="info">
                        <div class="left">所在地:</div>
                        <div class="right">{{ info.address || '--' }}</div>
                    </div>
                    <div class="info">
                        <div class="left">真实姓名:</div>
                        <div class="right">{{ info.realname || '--' }}</div>
                    </div>
                    <div class="info">
                        <div class="left">电子邮箱:</div>
                        <div class="right">{{ info.email || '--' }}</div>
                    </div>
                    <div class="info">
                        <div class="left">微信号:</div>
                        <div class="right">{{ info.wechat_num || '--' }}</div>
                    </div>
                    <div class="info">
                        <div class="left">省市区:</div>
                        <div class="right" v-if="info.province_text">{{ info.province_text + info.city_text + info.area_text }}</div>
                        <div class="right" v-else>--</div>
                    </div>
                    <div class="info">
                        <div class="left">邮寄地址:</div>
                        <div class="right">{{ info.post_address || '--' }}</div>
                    </div>
                </div>
                <div class="details_txt">
                    <div class="title">个人简介:</div>
                    <div class="txt">{{ info.intro || '--' }}</div>
                </div>
                <div class="details_txt">
                    <div class="title">可分享资源:</div>
                    <div class="txt">{{ info.share_resource || '--' }}</div>
                </div>
                <div class="details_txt">
                    <div class="title">我的需求:</div>
                    <div class="txt">{{ info.requirement || '--' }}</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            title: '查看资料',
            dialogVisible: false,
            loading: false,
            id: '',
            info: '',
        };
    },

    computed: {},

    watch: {},

    created() { },

    mounted() { },

    methods: {
        // 打开
        opendialog(id) {
            this.id = id
            this.dialogVisible = true
            this.getInfo()
        },
        getInfo() {
            this.loading = true
            var params = {}
            this.$memberApi.user_info(params, this.id).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.info = res.result
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 关闭
        cancelBtn() {
            this.dialogVisible = false
        },
    }
}
</script>
<style lang='scss' scoped>
.member_details {
    .details_info {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .info {
            width: 50%;
            padding-bottom: 15px;
            display: flex;

            .left {
                width: 100px;
                font-size: 14px;
                color: #333;
                font-weight: 500;
            }

            .right {
                font-size: 14px;
                color: #666;
                line-height: 20px;
            }
        }
    }

    .details_txt {
        padding-bottom: 15px;
        width: 100%;

        .title {
            width: 100px;
            font-size: 14px;
            color: #333;
            font-weight: 500;
        }

        .txt {
            width: 100%;
            font-size: 14px;
            color: #666;
            padding-top: 5px;
            line-height: 20px;
        }
    }
}
</style>