<!-- @Author: Yu_Bo 2023-10-19 14:24:21-->
<template>
    <div class="member_open zc_dialog_box">
        <el-dialog :visible.sync="dialogVisible" width="510px" :close-on-click-modal='false' @close='cancelBtn'>
            <div slot="title">
                <div class="name">{{ title }}</div>
            </div>
            <div class="open_info">
                <div class="info">
                    <div class="left">昵称：</div>
                    <div class="right">
                        <el-input v-model="info.nickname" disabled maxlength="10" placeholder="请输入名称"></el-input>
                    </div>
                </div>
                <div class="info">
                    <div class="left">登录手机号：</div>
                    <div class="right">
                        <el-input v-model="info.mobile" disabled maxlength="10" placeholder="请输入名称"></el-input>
                    </div>
                </div>
                <div class="info">
                    <div class="left">VIP有效时间：</div>
                    <div class="right">
                        <el-date-picker v-model="dateTime" type="daterange" range-separator="至" 
                            start-placeholder="VIP开始日期" end-placeholder="VIP结束日期" 
                            value-format="yyyy-MM-dd">
                    </el-date-picker>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
                <el-button class="btnBgColor_blue" size="small" :loading='btnLoading' type="primary" @click="affirmBtn">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            title: '开通会员',
            dialogVisible: false,
            info: '',
            dateTime:'',
            btnLoading:false,
        };
    },

    computed: {},

    watch: {},

    created() { },

    mounted() { },

    methods: {
        // 打开
        opendialog(info) {
            this.info = info
            if(info.vip_start && info.vip_end){
                var arr = []
                arr.push(info.vip_start)
                arr.push(info.vip_end)
                this.dateTime = arr
            }else{
                this.dateTime = ''
            }
            this.dialogVisible = true
        },
        // 关闭
        cancelBtn() {
            this.dialogVisible = false
        },
        // 确定
        affirmBtn(){
            var that = this
            this.btnLoading = true
            var params = {
                id: that.info.id,
                vip_start:this.dateTime?this.dateTime[0]:'',
                vip_end:this.dateTime?this.dateTime[1]:'',
            }
            this.$memberApi.change_vip_time(params).then(res => {
                this.btnLoading = false
                if (res.code == 1000) {
                    that.$succMsg(res.message)
                    that.cancelBtn()
                    that.$emit('submit')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
    }
}
</script>
<style lang='scss' scoped>
.member_open {
    .open_info{
        width: 100%;
        
        .info{
            margin-top: 20px;
            width: 100%;
            display: flex;
            align-items: center;
            .left{
                width: 120px;
                font-size: 14px;
                color: #333;
                text-align: right;
                padding-right: 15px;
            }
            .right{
                width: 350px;
                .el-date-editor{
                    width: 100%;
                }
            }
        }
        .info:first-child{
            margin-top: 0;
        }
    }
}
</style>